import React from 'react'
import { Col } from 'react-bootstrap'
import Layout from '../layouts/layout'
import Content from '../components/Content'
import OrderForm from '../components/contact/OrderForm'

const Order: React.FC = () => (
  <Layout>
    <Content className="mb-4">
      <h1>Форма заявки</h1>
      <Col lg={8}>
        <OrderForm />
      </Col>
    </Content>
  </Layout>
)

export default Order
