import React from 'react'
import { Button, Form } from 'react-bootstrap'
import { colors } from '../../styles/variables'

class OrderForm extends React.PureComponent {
  private send(): void {}

  render() {
    return (
      <Form onSubmit={this.send}>
        <Form.Group>
          <Form.Label>Вин код</Form.Label>
          <Form.Control name="vin" placeholder="XXXYYYZZZBBBAAAH" />
        </Form.Group>

        <Form.Group>
          <Form.Label>Телефон</Form.Label>
          <Form.Control name="phone" type="number" placeholder="Телефон" required />
        </Form.Group>

        <Form.Group>
          <Form.Label>Эл. почта</Form.Label>
          <Form.Control name="email" type="email" placeholder="boris.bv@example.com" />
        </Form.Group>

        <Form.Group>
          <Form.Label>Текст</Form.Label>
          <Form.Control name="text" as="textarea" rows="3" placeholder="Запчасти для ТО" />
        </Form.Group>

        <Form.Group>
          <Form.File name="data" label="Приложить фото" />
        </Form.Group>
        <Button style={{ background: colors.warning, border: 'none' }}>Отправить</Button>
      </Form>
    )
  }
}

export default OrderForm
